<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-sub-title class="mb-25">
          Total Transaction Amount
        </b-card-sub-title>
        <b-card-title class="font-weight-bolder">
          ${{ allTotal }}
        </b-card-title>
      </div>
      <!--/ title and subtitle -->

      <!-- account number input -->
      <b-form-group label="Account Number">
        <b-form-input v-model="accountNumber" @change="fetchTransactions" placeholder="Enter account number"></b-form-input>
      </b-form-group>
      <!--/ account number input -->

      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range' }"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div>
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts type="bar" height="350" :options="transChart.chartOptions" :series="transChart.series" />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle } from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';
import flatPickr from 'vue-flatpickr-component';
import { $themeColors } from '@themeConfig';
import axios from 'axios';

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    flatPickr,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
  },
  data() {
    return {
      rangePicker: ['2023-06-19', '2023-05-28'],
      loading: false,
      errored: false,
      transactions: [],
      accountNumber: '', // new property for account number
      transChart: {
        series: [
          {
            data: [100, 150, 180, 100, 110, 150, 150],
          },
        ],
        chartOptions: {
          // chart options...
        },
      },
    };
  },
  created() {
    this.fetchTransactions(); // fetch transactions on component creation
  },
  methods: {
    fetchTransactions() {
      axios
        .get(`${process.env.VUE_APP_API}/accounts/${this.accountNumber}`)
        .then(response => {
          this.transactions = response.data;
          this.updateChartSeries(); // update the chart series with new data
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
        });
    },
    updateChartSeries() {
      // Update the chart series with new data
      this.transChart.series = [
        {
          data: this.transactions.map(transaction => parseFloat(transaction.amount)),
        },
      ];
    },
  },
  computed: {
    allTotal() {
      let total = 0;
      for (let i = 0; i < this.transactions.length; i++) {
        total += parseFloat(this.transactions[i].amount);
      }
      return total;
    },
    getAllMonthsList() {
      const monthName = new Array(
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      );
      const d = new Date();
      const monthsList = [];
      d.setDate(1);
      for (let i = 0; i <= 11; i++) {
        monthsList.push(monthName[d.getMonth()] + ' ' + d.getFullYear());
        d.setMonth(d.getMonth() - 1);
      }
      return monthsList;
    },
  },
};
</script>